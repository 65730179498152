import { appointmentDurationInMinutes } from '@global/utils/domain/appointment';
import { teleheathPaths } from '@global/utils/url/paths';
import { InternalHealthCarerProfileTemplateProps } from '@lp-root/page-creators/hcp-profile.page-creator';
import IndexLayout, { IndexLayoutState } from '@lp-root/src/components/org.layout/layout.component';
import { PageProps } from '@lp-src/utils/local-types';
import PreUserAuthDataSource, { PreUserAuthDataSourceQueryParams } from '@web/data/pre-user-auth.datasource';
import { createPreUserDataSource } from '@web/data/vigilantes.datasource.factory';
import { graphql, navigate } from 'gatsby';
import QueryString from 'querystringify';
import * as React from 'react';
import { preUserTelehealthNavigate } from '../pre-user/pre-user-navigation';
import { ScheduleFormData } from '../schedule-page/schedule-form-row.component';
import { PreUserAgendarPageParams } from '../schedule-page/schedule-page.utils';
import HcpProfileTemplate from './telehealth-profile';
import { HcpProfileTemplateQueryOutput, PreUserHcpProfilePageParams, getPreUserHcpProfilePageParams } from './telehealth.utils';

const TelehealthProfileWeb: React.FunctionComponent<PageProps<InternalHealthCarerProfileTemplateProps, HcpProfileTemplateQueryOutput>> = (
  props
) => {
  const datasource = React.useRef(createPreUserDataSource());
  const { education, approaches, themes, full_description, health_carer, profile_url } = props.data?.hasura.health_carer_telehealth?.[0];
  const [pageParams, setPageParams] = React.useState<PreUserHcpProfilePageParams>({
    preUserId: '',
    token: '',
    davId: '',
    nextAvailableAppointmentDate: new Date(),
  });

  React.useEffect(() => {
    try {
      const params = getPreUserHcpProfilePageParams();
      if (!params.preUserId) navigate(-1);
      PreUserAuthDataSource.setToken(params.token);
      setPageParams(params);
    } catch (error) {
      console.error('ERROR: agendar.tsx:23 ~ React.useEffect ~ error', error);
      navigate(-1);
    }
  }, []);

  const redirectToRegisterPage = (data: ScheduleFormData) => {
    preUserTelehealthNavigate<PreUserAgendarPageParams, never>({
      path: teleheathPaths.preUserSchedule.path,
      queryParam: {
        preUserId: pageParams.preUserId,
        hcpId: health_carer.id,
        hcpName: health_carer.name,
        nextAvailableAppointmentDate: pageParams.nextAvailableAppointmentDate,
        date: data.date.toISOString(),
        time: data.time,
      },
    });
  };

  const handleScheduleSuccess = () => {
    navigate(
      `${teleheathPaths.preUserStart.path}?${QueryString.stringify({
        t: PreUserAuthDataSource.getToken(),
      } satisfies PreUserAuthDataSourceQueryParams)}`,
      { state: { flashMessage: 'Consulta agendada com sucesso!', flashType: 'success' } satisfies IndexLayoutState }
    );
  };

  return (
    <IndexLayout {...props}>
      <HcpProfileTemplate
        user={{
          id: pageParams?.preUserId,
          hasDavId: !!pageParams?.davId,
        }}
        hcp={{
          id: health_carer.id,
          profileUrl: profile_url,
          name: health_carer.name,
          professionalId: health_carer.professional_id,
          appointmentDurationInMinutes,
          fullDescription: full_description,
          themes,
          approaches,
          education,
        }}
        nextAvailableAppointmentDate={pageParams.nextAvailableAppointmentDate.toISOString()}
        datasource={datasource.current}
        handleScheduleSuccess={handleScheduleSuccess}
        onRedirectRegisterPageClick={redirectToRegisterPage}
      />
    </IndexLayout>
  );
};

export default TelehealthProfileWeb;

export const query = graphql`
  query HcpProfileTemplateQuery($hcpId: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    hasura {
      health_carer_telehealth(where: { health_carer_id: { _eq: $hcpId } }) {
        approaches
        education
        profile_url
        themes
        full_description
        health_carer {
          id
          name
          professional_id
          professional_id_state
        }
      }
    }
  }
`;
